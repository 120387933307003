body {
  font-family: "Roboto", sans-serif;
}

a {
  text-decoration: none;
  color: #d6d8dc;
}

a:hover,
.banner-wrapper i,
.footer-wrapper span,
.pricing-wrapper .slick-next::before,
.pricing-wrapper .slick-prev::before,
.banner-wrapper .sign-out-btn a:hover,
.banner-wrapper .get-started-btn a:hover {
  color: #fff;
}

h1,
.testimonial-wrapper h2,
.pricing-wrapper .pricing-heading h2 {
  font-size: 2.75rem;
  color: #0f1c26;

}
.testimonial-wrapper h2,
.pricing-wrapper .pricing-heading h2{
  text-decoration: underline;
}

.pricing-wrapper .price-card .price-text span,
.testimonial-wrapper .client-text p {
  font-size: 0.75rem;
}

.pricing-wrapper .price-card .price-text,
.pricing-wrapper .price-card .price-text h3,
.footer-wrapper .footer-socials a {
  display: flex;
  justify-content: center;
  align-items: center;
}

h1,
h3,
.testimonial-wrapper .carousel-inner p,
.testimonial-wrapper .client-text p,
.pricing-wrapper .price-card .buy-btn button {
  font-weight: 700;
}
h2,
h3,
p {
  margin-bottom: 0;
}

.testimonial-wrapper .carousel-indicators [data-bs-target],
.pricing-wrapper .slick-dots li button {
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 100%;
  background: #005f2f;
  border-top: 0;
  border-bottom: 0;
  margin-left: 0;
  opacity: 1;
}

/* Banner-Wrapper Starts*/

.banner-wrapper {
  background: url(../assets/banner.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 2rem 0 6rem;
}

.banner-wrapper .navbar-nav .nav-item .nav-link {
  color: #a7afc0;
  position: relative;
  display: inline-block;
  padding: 0 0 0.5rem;
  margin: 0 3rem 0 0;
  font-weight: 500;
}

.banner-wrapper .navbar-nav .nav-item .nav-link:hover,
.banner-wrapper .navbar-nav .nav-item .nav-link.active {
  color: #00ab55;
}

.banner-wrapper .navbar-nav .nav-item .nav-link::before,
.banner-wrapper .sign-out-btn a:hover,
.banner-wrapper .get-started-btn a:hover,
.pricing-wrapper .slick-next::before,
.pricing-wrapper .slick-prev::before {
  background: #00ab55;
}

.banner-wrapper .navbar-nav .nav-item .nav-link::before {
  transition: 300ms;
  height: 0.25rem;
  content: "";
  position: absolute;
  width: 0%;
  bottom: 0;
}

.banner-wrapper .navbar-nav .nav-item .nav-link:hover::before,
.banner-wrapper .navbar-nav .nav-item .nav-link.active::before {
  width: 100%;
}

.banner-wrapper .sign-out-btn a,
.banner-wrapper .get-started-btn a {
  background: #00ab55;
  color: #d6d8dc;
  border-radius: 3.125rem;
  transition: 0.3s;
  display: inline-block;
  font-weight: 500;
}

.banner-wrapper .sign-out-btn a {
  padding: 0.75rem 2rem;
}

.banner-wrapper .get-started-btn a {
  padding: 0.938rem 1.5rem;
}

.banner-wrapper i {
  vertical-align: middle;
}

.banner-wrapper h1 {
  margin-bottom: 0.75rem;
  text-decoration: none;
}

.banner-wrapper p {
  color: #929baf;
}

.banner-wrapper .navbar-toggler:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.banner-wrapper .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

@media (max-width: 61.938rem) {
  .banner-wrapper .navbar-nav .nav-item .nav-link {
    margin: 0 0 0.875rem 0;
  }
}

/* Banner-Wrapper Ends*/

/* Pricing-Wrapper Starts*/
.pricing-wrapper {
  background: #eefbfe;
  padding: 6rem 0 3rem;
}

.pricing-wrapper .price-card {
  background: #00ab55;
  color: #fff;
  border-radius: 1.25rem;
  padding: 4rem 3rem;
  position: relative;
}

.pricing-wrapper .slick-current .price-card {
  background: linear-gradient(
    180deg,
    rgba(0, 171, 85, 1) 0%,
    rgba(0, 146, 72, 1) 50%,
    rgba(0, 120, 60, 1) 100%
  );
}

.pricing-wrapper .price-card .price-text {
  width: 6rem;
  height: 6rem;
  border-radius: 3.125rem;
  background: linear-gradient(
    90deg,
    rgba(0, 171, 85, 1) 0%,
    rgba(0, 146, 72, 1) 35%,
    rgba(0, 120, 60, 1) 90%
  );
}

.pricing-wrapper .price-card .price-text h3 {
  font-size: 2rem;
}

.pricing-wrapper .price-card h2 {
  font-size: 0.938rem;
  margin-top: 3rem;
  text-align: center;
}

.pricing-wrapper .price-card .buy-btn {
  position: absolute;
  bottom: 3rem;
  left: 0;
  right: 0;
  text-align: center;
}

.pricing-wrapper .price-card .buy-btn button {
  border: 0.063rem solid #fff;
  display: inline-block;
  background-color: transparent;
  border-radius: 3.125rem;
  padding: 0.938rem 2.5rem;
  font-size: 0.875rem;
  color: #fff;
  line-height: 1;
}

.pricing-wrapper .price-card .buy-btn button:hover,
.pricing-wrapper .slick-current .buy-btn button,
.pricing-wrapper .slick-current .price-card .price-text {
  background: #fff;
  color: #00ab55;
}

.pricing-wrapper .price-card ul {
  margin-bottom: 7rem;
}

.pricing-wrapper .price-card li {
  margin-bottom: 0.75rem;
  color: #d6d8dc;
  font-size: 0.938rem;
}

.pricing-wrapper .slick-active {
  padding: 7rem 0;
}

.slick-slide:not(.slick-active) {
  margin: 7rem 0;
}

.pricing-wrapper .slick-slider {
  margin-bottom: 4rem;
}

.pricing-wrapper .slick-center {
  transform: scale(1.15);
  position: relative;
  z-index: 1;
}

/* Slick Dots */
.pricing-wrapper .slick-dots li button:before {
  display: none;
}

.pricing-wrapper .slick-dots li.slick-active,
.pricing-wrapper .slick-dots li.slick-active button {
  width: 2rem;
  border-radius: 1.023rem;
}

.pricing-wrapper .slick-dots li.slick-active {
  margin: 0 0.188rem 0 0;
}

.pricing-wrapper .slick-dots li.slick-active button {
  background: #005f2f;
}

.pricing-wrapper .slick-dots {
  bottom: -7rem;
}

/* Slick Arrows */
.pricing-wrapper .slick-next,
.pricing-wrapper .slick-prev {
  width: 2.25rem;
  height: 2.25rem;
}

.pricing-wrapper .slick-prev {
  left: -2.5rem;
}

.pricing-wrapper .slick-next {
  right: -2.5rem;
}

.pricing-wrapper .slick-next::before,
.pricing-wrapper .slick-prev::before {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3.125rem;
  width: 2.25rem;
  height: 2.25rem;
  opacity: 1;
  font-size: 0.875rem;
}

.pricing-wrapper .slick-prev:before,
.pricing-wrapper .slick-next:before {
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
}

.pricing-wrapper .slick-next:before {
  content: "\3e";
}

.pricing-wrapper .slick-prev:before {
  content: "\3c";
}
.pricing-wrapper .slide {
  padding: 3rem 0.5rem;
}
@media (max-width: 61.938rem) {
  .pricing-wrapper {
    padding: 4rem 0 2rem;
  }

  .pricing-wrapper .slick-active {
    padding: 0;
  }

  .pricing-wrapper .slide {
    padding: 3rem 0.5rem;
  }

  .slick-slide:not(.slick-active) {
    margin: 0;
  }

  .pricing-wrapper .slick-dots {
    bottom: -4rem;
  }
}

/* Pricing-Wrapper Ends*/

/* Testimonial-Wrapper Starts*/
.testimonial-wrapper {
  background: url(../assets/testimonial.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 5rem 0 7rem;
}

.testimonial-wrapper h3 {
  font-size: 1.5rem;
  font-weight: 500;
  color: #76819b;
}

.testimonial-wrapper .carousel-inner p {
  font-style: italic;
  color: #929baf;
  line-height: 1.9;
  margin-bottom: 1rem;
}

.testimonial-wrapper .carousel-indicators {
  bottom: -3rem;
}

.testimonial-wrapper .carousel-indicators button.active {
  width: 2rem;
  border-radius: 1.023rem;
}

.testimonial-wrapper .client-text h3 {
  font-size: 1rem;
  color: #005f2f;
}

.testimonial-wrapper .client-text p {
  font-style: normal;
  color: #5f6675;
  margin-bottom: 0;
}

.testimonial-wrapper img {
  width: 3rem;
  height: 3rem;
  border-radius: 3.125rem;
  margin-right: 1rem;
  object-fit: cover;
}

/* Testimonial-Wrapper Ends*/

/* Footer-Wrapper Starts*/
.footer-wrapper {
  background: #00ab55;
  color: #d6d8dc;
  padding: 4rem 0 1rem;
}

.footer-wrapper span,
.footer-wrapper ul li {
  margin-bottom: 0.5rem;
}

.footer-wrapper .para {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.footer-wrapper .copyright-text {
  padding-top: 5rem;
}

.footer-wrapper .copyright-text p {
  font-size: 1rem;
}

.footer-wrapper .footer-socials a {
  border: 0.063rem solid #fff;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 3.125rem;
}

.footer-wrapper .footer-socials li {
  margin-bottom: 0.75rem;
}

.footer-wrapper .footer-socials i {
  font-size: 0.75rem;
}

.footer-wrapper .footer-socials a:hover {
  background: #fff;
  color: #00ab55;
}

@media (max-width: 61.938rem) {
  .footer-wrapper .copyright-text {
    padding-top: 2rem;
  }

  .footer-wrapper .footer-socials a {
    margin: 0 auto;
  }
}

/* Footer-Wrapper Ends*/

#fa-solid {
  margin: 0 !important;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  border: none !important;
}
