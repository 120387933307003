.common-border {
  border: solid 1px transparent;
  border-radius: 12px;
}

.chat-container {
  background-color: #f4f6f8;
}

.chat-users {
  height: 91.8vh;
  background-color: #f4f6f8;
  border-right: solid 1px rgba(128, 128, 128, 0.479);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  position: relative;
  overflow-y: hidden;
}

.chat-messages-parent {
  height: 84vh;
  background-color: #f4f6f8;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.chat-messages {
  display: block;
}

.chat-messages-full {
  display: none;
}

.chat-list-button-container {
  display: none;
  position: relative;
  bottom: 0.5rem;
  left: -0.5rem;
}

.chat-users-hidden {
  display: none;
}

@media screen and (max-width: 768px) {
  .chat-messages-full {
    display: block;
  }

  .chat-messages {
    display: none;
  }

  .chat-users {
    display: none;
  }

  .chat-list-button-container {
    display: block;
  }

  .chat-users-hidden {
    display: block;
    height: 91.8vh;
  }
}

/* .chat-tabs {
  margin-top: 1rem;
} */
.user-card-parent {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  /* border: solid 1px black; */
  cursor: pointer;
  padding: 0.2rem;
}

.user-card-parent:hover {
  background-color: #3dda791c;
}

.chat-avatar {
  height: 33px;
  width: 33px;
  border: solid 1px rgb(126, 126, 126);
}

.messages-count {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.center-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.add-chat-btn {
  background-color: rgb(0, 171, 85);
  position: absolute;
  right: 11px;
  bottom: 11px;
}

.add-chat-btn:hover {
  background-color: rgb(0, 171, 85);
}

@media screen and (max-width: 768px) {
  .chat-messages-parent {
    height: 81vh;
  }

  .messages-container {
    height: 72.5vh;
  }
}

.group-menu-icon {
  position: relative;
  bottom: 6px;
}

.group-menu-icon {
  position: relative;
  bottom: 6px;
}

.ml-auto {
  margin-left: auto !important;
}

.messages-container {
  height: 72.5vh;
  /* overflow-y: ; */
  z-index: 1;
  margin-bottom: 4px;
  /* height: 54vh; */
  /* background: #828282; */
}

.load-more-chat {
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 3;
  width: 100%;
}

.scroller {
  height: inherit;
  overflow-y: auto;
  overflow-x: hidden;
}

.scroller::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.scroller::-webkit-scrollbar {
  width: 0.4rem;
  background-color: #f5f5f5;
}

.scroller::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #bebebe;
  /* background-color: #8383839c; */
}

.sent-message {
  background-color: rgba(255, 255, 255);
  /* padding: 12px 20px; */
  border-radius: 8px 8px 0 8px;
  position: relative;
}

.sent-message:before {
  content: "";
  position: absolute;
  border: 5px solid transparent;
  border-left-color: rgba(255, 255, 255);
  border-top-color: rgba(255, 255, 255);
  left: auto;
  right: 0;
  bottom: -10px;
}

.message-item {
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.212); */
  border: solid 1px rgba(145, 158, 171, 0.24);
  /* border-radius: 4px; */
  padding: 0.3rem;
  margin: 0.1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  position: relative;
  min-width: 10rem;
  max-width: 96%;
}

.message-item p {
  margin: 0px !important;
}

.message-item ol {
  margin-left: 1rem;
}

.message-item ul {
  margin-left: 1.2rem;
}

.message-item img {
  margin: auto;
}

.mr-1 {
  margin-right: 4px !important;
}

.clickable-link {
  font-size: 14px;
  font-weight: 400;
}

.message-margin {
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
}

.received-message {
  background-color: rgba(145, 255, 200, 0.8);
  /* padding: 12px 20px; */
  border-radius: 8px 8px 8px 0;
  position: relative;
}

.received-message:before {
  content: "";
  position: absolute;
  border: 5px solid transparent;
  border-left-color: rgba(145, 255, 200, 0.8);
  border-top-color: rgba(145, 255, 200, 0.8);
  left: 0;
  right: auto;
  bottom: -10px;
}

/* .chat-bottom-bar {
  position: relative;
  bottom: 3%;
} */
.message-editor {
  width: 100%;
  position: relative;
  /* bottom: 15px; */
  /* bottom: -3.8vh; */
}

.message-editor .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  height: 13.5vh !important;
}

.message-editor .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  height: 13.5vh !important;
  border: solid 1px #3ab469;
}

.message-editor ul,
ol {
  margin-left: 1rem;
}

.text-area-parent {
  position: relative;
}

.send-message-area {
  width: 100%;
  height: 3rem;
  border-radius: 0.5rem;
  resize: none;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
}

.send-message-area:active,
:focus,
:focus-visible,
:focus-within {
  outline: none;
}

.send-message-area::-webkit-scrollbar {
  max-height: 0.28rem !important;
  max-width: 0.28rem;
}

.send-message-area::-webkit-scrollbar-track {
  background: rgba(233, 233, 233, 0);
}

.send-message-area::-webkit-scrollbar-thumb {
  background-color: #a5a5a57e;
  border-radius: 20px;
}

.send-icon-parent {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .new-msg-sidebar-mobile {
    position: relative;
    top: 0px;
  }
}

.team-list-parent {
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  justify-content: start;
  align-items: center;
}

.chat-avatar {
  height: 33px;
  width: 33px;
  border: solid 1px rgb(126, 126, 126);
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.m-0 {
  margin: 0 !important;
}

.floating-image-bar {
  position: absolute;
  /* background-color: #f4f6f8; */
  height: 52px;
  padding-top: 4px;
  padding-bottom: 4px;
  width: 97.8%;
  bottom: 57px;
  z-index: 2;
}

.message-image-container {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0.3rem;
  height: 50px;
  overflow: hidden;
  padding: 0.1rem;
  margin-bottom: 0.1rem;
  position: relative;
}

.message-image-cross {
  position: absolute;
  color: rgba(255, 0, 0, 0.808);
  top: -2px;
  left: 43px;
  cursor: pointer;
  z-index: 4;
}

.pr-0 {
  padding-right: 0px !important;
}

.add-chat-btn-hidden {
  background-color: rgb(0, 171, 85);
  position: absolute;
  right: 11px;
  bottom: 19px;
}

.add-chat-btn-hidden:hover {
  background-color: rgb(0, 171, 85);
}

/* Custom Scrollbar */
.container-with-scrollbar {
  position: relative;
  overflow: hidden;
  width: calc(100% + 16px);
  /* Add extra width to accommodate the scrollbar */
}

.container-with-scrollbar:hover {
  overflow: auto;
  /* Display the scrollbar on hover */
}

.container-with-scrollbar::-webkit-scrollbar {
  width: 0px;
  /* Width of the scrollbar */
}

.container-with-scrollbar:hover::-webkit-scrollbar {
  width: 8px;
  /* Width of the scrollbar */
}

.container-with-scrollbar:hover::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Background color of the scrollbar track */
}

.container-with-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Color of the scrollbar thumb */
  border-radius: 4px;
  /* Rounded corners of the scrollbar thumb */
}

.container-with-scrollbar:hover::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Color of the scrollbar thumb on hover */
}

.image-div {
  height: "200px";
  /* background-color: rgba(255, 255, 255, 0); */
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  position: absolute;
  width: 100%;
  bottom: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}